<template>
  <trac-loading class="pt-64" v-if="loading" />
  <main v-else class="mr-4 md:mr-0">
    <trac-back-button>Payments</trac-back-button>
    <div class="md:mt-8">
      <div class="flex justify-between">
        <div class="mt-3 flex items-center">
          <!-- <div>
            <h2 class="tiny text-primaryGray">Pending Payout</h2>
            <h1 class="text-base text-primaryBlue mt-2 font-medium">
              {{ total | formatPrice }}
            </h1>
          </div> -->
        </div>
        <div
          v-if="false"
          class="max-w-xs w-full px-6 relative small-shadow rounded-md flex items-center py-4 bg-white"
        >
          <img
            src="@/assets/svg/payoutalert.svg"
            class="w-10 ml-2 h-10"
            alt=""
          />
          <div>
            <div
              class="tiny flex items-center ml-6 pr-10 uppercase font-medium text-primaryBlue"
            >
              DUE TOMORROW
              <svg
                class="w-2 h-3 ml-2 stroke-current"
                viewBox="0 0 7 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 11.5L6.4 6.1L1 0.700001"
                  stroke="#003283"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <svg
            class="w-6 h-6 absolute top-0 right-0 -mr-2 -mt-1"
            viewBox="0 0 27 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="13.5"
              cy="14"
              r="13.5"
              fill="#253B95"
              fill-opacity="0.33"
            />
            <circle
              cx="13.5"
              cy="14"
              r="10.5"
              fill="#253B95"
              fill-opacity="0.72"
            />
            <circle cx="13.4546" cy="14" r="6.54541" fill="#253B95" />
          </svg>
        </div>
      </div>
    </div>
    <div class="w-full mt-10">
      <div>
        <div
          :style="`grid-template-columns:1fr 1fr `"
          class="grid rounded-tl-md rounded-tr-md border"
        >
          <div
            v-for="(header, index) in getTableHeader"
            :key="index"
            :class="[
              index == 0 || index == getTableHeader.length - 1
                ? 'rounded-tl-md rounded-tr-md'
                : '',
              { 'grid-space+': index == 0 },
            ]"
            class="pl-8 py-5 bg-accentLight uppercase text-xs font-medium w-full"
          >
            {{ header }}
          </div>
        </div>
      </div>
      <div v-if="filteredBy && filteredBy.length">
        <div
          :style="`grid-template-columns: 1fr 1fr `"
          :class="[
            i == payments.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
          ]"
          class="grid last:border-b border-l hov cursor-pointer border-r even:bg-veryLight items-center"
          v-for="(payment, i) in filteredBy"
          :key="i"
          @click="
            $router.push({
              name: 'PayoutDetails',
              params: {
                payoutId: payment.payout_id,
              },
            })
          "
        >
          <div
            class="w-full flex items-center py-4 pl-8 text-xs text-accentDark font-normal"
          >
            {{ payment.total_value_payments | formatPrice }}
          </div>
          <div
            class="w-full py-4 pl-8 text-xs text-accentDark font-normal flex-col"
          >
            {{ payment.number_of_payments }} payments
          </div>
          <!-- <div
            class="w-full py-4 pl-8 text-xs text-accentDark font-normal flex-col"
          >
            {{
              checkDate(moment(payment.date_of_payments).format("Do-MMM-YYYY"))
            }}
          </div>

          <div
            class="w-full py-4 pl-8 text-xs font-normal flex justify-between"
            :class="status(payment.payment_status)"
          >
            {{ payment.payment_status }}
            <svg
              class="w-5 h-5 mr-6"
              viewBox="0 0 4 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="1.825"
                cy="7.5999"
                r="0.825"
                stroke="#253B95"
                stroke-width="0.9"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="1.825"
                cy="1.825"
                r="0.825"
                stroke="#253B95"
                stroke-width="0.9"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle
                cx="1.825"
                cy="13.3748"
                r="0.825"
                stroke="#253B95"
                stroke-width="0.9"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div> -->
        </div>
      </div>
      <div v-else>
        <div class="border py-3 pl-5 text-xs border-t-0">
          <trac-center-data>
            <div class="h-40 flex items-center text-lg text-gray-600">
              There is currently no data available.
            </div>
          </trac-center-data>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      getTableHeader: ["Amount", "No of transaction"],
      // getTableHeader: ["Amount", "No of transaction", "Payment Date", "Status"],
      payments: [],
      loading: false,
      moment,
      total: null,
    };
  },
  computed: {
    filteredBy() {
      return this.payments || [];
    },
    // total() {
    //   let k = this.filteredBy.reduce((a, { total_value_payments }) => a + total_value_payments, 0)
    //   return k
    // }
  },
  methods: {
    async makeCall() {
      this.loading = true;
      let res = await this.$store.dispatch("GET_BATCH_PAYMENT");
      if (res.status) {
        this.total = res.data.total_pending_payments;
        this.payments = res.data.batch_payments;
        this.loading = false;
      }
    },
    checkDate(x) {
      if (x.includes("3000")) {
        return "To be paid";
      } else {
        return x;
      }
    },
    status(x) {
      if (x === "paid") {
        return "text-green-500 cap font-medium";
      } else if (x === "pending") {
        return "text-yellow-500 cap font-medium";
      }
    },
  },
  async created() {
    await this.makeCall();
  },
};
</script>

<style></style>
